/**
 * Splits a slug into multiple slug segments
 *
 * @param slug the page content from Contentul
 */
export const splitPath = (slug: string): string[] =>
  slug.includes('/') && slug !== '/' ? slug.split('/') : [slug];

/**
 * Joins multiple slug segments into a slug with a leading slash because
 * Contentful's Content Delivery API has issues with leading slashes.
 *
 * @param segments the array of slug segments
 */
export const joinSegments = (segments: string | string[] | undefined): string =>
  Array.isArray(segments) ? segments.join('/') : `/${segments}`;

/**
 * Slug string to corresponding filename (homepage check)
 */
export const slugToFilename = (slug: string) => (slug === '/' ? 'home' : slug);
