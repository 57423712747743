import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import React from 'react';
import { useTracking } from 'react-tracking';
import { ALLOW_COPY_XRAY } from '@peloton/app-config';
import { NextMetadataTags } from '@peloton/next/components/MetadataTags';
import toGetStaticProps from '@peloton/next/data/toGetStaticProps';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { SkipLinkToMain, Main } from '@acme-ui/global/skip-links';
import Nav from '@acme-ui/nav/Nav';
import useNextPage from '@content/client/hooks/useNextPage';
import CartPanel from '@ecomm/cart-next/panel/Panel';
import type { TypePage } from '@page-builder/lib/types';
import BannerOrVariation from '@page-builder/modules/Banner/BannerOrVariation';
import BlockProvider from '@page-builder/modules/Block/BlockProvider';
import NextBlock from '@page-builder/modules/Block/NextBlock';
import { toBannerProps, toPageData } from '@page-builder/utils/pages';

const DEFAULT_TITLE =
  'Peloton® | Exercise Bike With Indoor Cycling Classes Streamed Live & On-Demand';

const CopyXray = dynamic(() => import('@ecomm/copy-xray/CopyXray'), {
  ssr: false,
});

const Footer = dynamic(() => import('@acme-ui/footer'), {
  ssr: false, // Needs access to window for one trust
});
const EcommPage = dynamic(() => import('@page-builder/layout/NextEcommPage'));

type PageProps = {
  page: TypePage;
};
// Force build next-www for Apollo 3 prod test some more
const Homepage: NextPage<PageProps> = ({ page: fallbackPage }) => {
  const page = useNextPage(fallbackPage);
  const {
    title,
    keywords,
    description,
    pageTypeId,
    slug,
    imageUrl,
    id,
    pageContent,
    name,
    noIndex,
  } = toPageData(page);
  const { Track } = useTracking({
    parent: pageTypeId,
  });
  const locale = useLocale();

  if (!pageContent) {
    return <div></div>;
  }

  const { banner: bannerProps, hideBanner } = toBannerProps(page);

  return (
    <>
      <NextMetadataTags
        title={title || DEFAULT_TITLE}
        keywords={keywords || []}
        description={description || ''}
        locale={locale}
        slug={slug}
        imageUrl={imageUrl}
        noIndex={noIndex}
      />
      <BlockProvider>
        <Track>
          {ALLOW_COPY_XRAY && <CopyXray />}

          {!hideBanner && <BannerOrVariation data={bannerProps} />}
          <SkipLinkToMain />
          <Nav transparent={true} />
          <Main>
            <EcommPage
              name={name}
              key={`${pageTypeId}-${id}`}
              {...pageContent.fields}
              block={NextBlock}
            />
          </Main>
          <CartPanel />
          <Footer locale={locale} />
        </Track>
      </BlockProvider>
    </>
  );
};

export default Homepage;

export const getStaticProps = toGetStaticProps({
  toPageSlug: () => '/',
});
