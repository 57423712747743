import type React from 'react';
import { TLD } from '@peloton/app-config';
import { isTldTranslationIncomplete } from '@peloton/internationalize';
import type { PageContentTypes } from '@page-builder/lib/constants';
import type {
  TypeInstructorMatchResultsPageFields,
  TypePage,
  TypePageEcommPageFields,
  TypePageLegalPageFields,
  TypePage_marketingPageFields,
} from '@page-builder/lib/types';

type PageWithContent = {
  fields: {
    content: {
      sys: {
        id: string;
        contentType: {
          sys: {
            id: string;
          };
        };
      };
      fields: unknown;
    };
  };
};

export const toPageTypeId = (page: PageWithContent): string => {
  return page.fields.content.sys.contentType.sys.id;
};

export type PageTypeMap<T = {}> = {
  [PageContentTypes.LegalPage]: React.FC<
    React.PropsWithChildren<T & TypePageLegalPageFields>
  >;
  [PageContentTypes.MarketingPage]: React.FC<
    React.PropsWithChildren<T & TypePage_marketingPageFields>
  >;
  [PageContentTypes.EcommPage]: React.FC<
    React.PropsWithChildren<T & TypePageEcommPageFields>
  >;
  [PageContentTypes.InstructorMatchResultsPage]: React.FC<
    React.PropsWithChildren<T & TypeInstructorMatchResultsPageFields>
  >;
};

export const toPageHandlerFromMap = <K extends TypePage, T = {}>(
  page: K,
  map: Partial<PageTypeMap<T>>,
) => {
  type HandlerProps = K['fields']['content']['fields'];
  const pageTypeId = toPageTypeId(page);
  const props: HandlerProps = page.fields.content.fields;
  const Handler: React.FC<React.PropsWithChildren<HandlerProps>> | undefined =
    map[pageTypeId];
  if (Handler) {
    return { Handler, props };
  }

  throw new Error(`Cannot handle page type: ${pageTypeId}`);
};

export const toPageContentFields = <T extends PageWithContent>(
  page: T,
): T['fields']['content']['fields'] => {
  return page.fields.content.fields;
};

export const toBannerProps = (page: TypePage) => {
  const fields = toPageContentFields(page);
  const banner = 'banner' in fields ? fields.banner : undefined;
  const hideBanner = 'hideBanner' in fields ? Boolean(fields.hideBanner) : undefined;
  return {
    banner,
    hideBanner,
  };
};

export type PageData = {
  name: string;
  title?: string;
  keywords?: string[];
  description?: string;
  imageUrl?: string;
  noIndex?: boolean;
  pageContent: TypePage['fields']['content'];
  pageTypeId: string;
  slug: string;
  id: string;
};

export const toPageData = (page: TypePage): PageData => {
  // TODO: Remove this when .at homepage is published
  if (isTldTranslationIncomplete(TLD) && !page) {
    return {} as any;
  }

  const { content: pageContent, slug, seo } = page.fields;
  const pageTypeId = pageContent.sys.contentType.sys.id;
  const { id } = page.sys;

  return {
    name: pageContent.fields.name || '',
    title: seo?.fields.title,
    keywords: seo?.fields.keywords,
    description: seo?.fields.description,
    imageUrl: seo?.fields.image?.fields.file.url,
    noIndex: seo?.fields?.no_index,
    pageContent,
    pageTypeId,
    slug,
    id,
  };
};
