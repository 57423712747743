import React from 'react';
import type { TypeComponentBanner, TypeVariation } from '@page-builder/lib/types';
import { toBannerOrVariation } from '@page-builder/utils/unions';
import Variation from '../Variation/NextVariation';
import Banner from './Banner';

type Props = {
  data?: TypeComponentBanner | TypeVariation;
};

const BannerOrVariation: React.FC<React.PropsWithChildren<Props>> = ({ data }) => {
  const result = data && toBannerOrVariation(data);
  if (result?.variation) {
    return <Variation {...result.variation} />;
  }

  return <Banner data={result?.moduleData} />;
};

export default BannerOrVariation;
