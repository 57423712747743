import type { Locale } from '@peloton/internationalize/models/locale';
import { splitPath } from '@content/client/lib/parseSlugs';
import {
  CLASS_DISCIPLINE_PAGES_REGEX,
  CLASS_PAGES_REGEX,
  FREE_CLASS_VIDEO_PAGES_REGEX,
  isClassIdSlug,
} from '@ecomm/classes/models/constants';
import { getClassListingPageSegments } from '@ecomm/classes/utils/getClassListingPageSegments';
import { excludePaginationSlug } from '../utils/pagination';

const TEMPLATE_MATCH = '_template_';

export const LISTING_PAGES = {
  matcher: CLASS_DISCIPLINE_PAGES_REGEX,
  parseSlug: (slug: string): string => {
    const segments = getClassListingPageSegments(slug);
    return `classes/${segments.disciplineOrLanding}`;
  },
};

export const CLASS_PAGES = {
  matcher: CLASS_PAGES_REGEX,
  slug: 'classes/_discipline_/_template_',
};

export const FREE_CLASS_VIDEO_PAGES = {
  matcher: FREE_CLASS_VIDEO_PAGES_REGEX,
  slug: 'classes/video/_template_',
};

/**
 * Gets the corresponding template slug for the provided page.
 * @param slug for the corresponding page
 * @returns the template slug for the provided page.
 */
export const getTemplateSlug = (slug: string): string => {
  const slugWithoutPagination = excludePaginationSlug(slug);

  if (LISTING_PAGES.matcher.test(slugWithoutPagination)) {
    return LISTING_PAGES.parseSlug(slug);
  }

  if (FREE_CLASS_VIDEO_PAGES.matcher.test(slugWithoutPagination)) {
    return FREE_CLASS_VIDEO_PAGES.slug;
  }

  if (CLASS_PAGES.matcher.test(slugWithoutPagination)) {
    return CLASS_PAGES.slug;
  }

  const isClassIdClassPage = isClassIdSlug(slug);

  if (isClassIdClassPage) {
    return CLASS_PAGES.slug;
  }

  return slugWithoutPagination;
};

/**
 * Allows you to fetch a number of slugs for a given template.
 * @param slug template slug i.e classes/_discipline_/_template_
 * @returns a slugs for the selected template
 */
export const fetchTemplateSlugs = (slug: string, locale: Locale) => {
  /**
   * if (slug === 'classes/_discipline_/_template_') {
   * return a list of slugs for the template
   * }
   */

  return [
    {
      locale,
      params: {
        slugs: splitPath(slug),
      },
    },
  ];
};

/**
 * Returns an array of strings without any formatting, 'raw' slugs.
 * @param slug template slug i.e classes/_discipline_/_template_
 * @returns a list of slugs for the provided template.
 */
export const fetchRawTemplateSlugs = (slug: string) => {
  return [];
};

export const isTemplateSlug = (slug: string) => slug.includes(TEMPLATE_MATCH);
